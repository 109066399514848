import {
  Avatar,

} from '@chakra-ui/react'
import * as React from 'react'
import { ToggleButton } from './ToggleButton'
import { ColorModeSwitcher } from './ColorModeSwitcher';

import { useState, useEffect } from "react";
import { Select, AsyncSelect } from "chakra-react-select";



export const CollectionSearch = (props) => {
  const setSelectedCollection=props.setSelectedCollection;
  const collectionDefaults=props.collectionDefaults;  
  


    
    



   return (
     <>
       <AsyncSelect
          cacheOptions
          isClearable="true"
        //  getOptionLabel={(e) => console.log(e)}}
        //  getOptionValue={(e) => e.name}
         loadOptions={inputValue => fetch('https://api.center.dev/v1/ethereum-mainnet/search?limit=10&query='+inputValue+'&type=collection', {method: 'GET', headers: {accept: 'application/json'}})
         .then(data => data.json())
         .then(data => data.results.map(({ name, id }) => {
          return { label: name, value: id }}))}
        //  onInputChange={(value) => {setQuery(value)}}
          onChange={(value) => setSelectedCollection(value)}
          placeholder="Search Collections..."
          defaultOptions={collectionDefaults}
          def
       />
     </>
   );
 };

