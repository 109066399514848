import * as React from 'react';
import { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Center,
  Container,
  Button,
  Link,
  VStack,
  Code,
  Grid,
  extendTheme,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
  filter,
  Tag,
  Spinner,
  CloseButton,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import { theme as proTheme } from '@chakra-ui/pro-theme'
import {
  MasterComponent,
} from "./components";
import { Navbar } from './Navbar'
import { CollectionSearch } from './CollectionSearch'
import { SelectTraits } from './SelectTraits'
import { ProductBreadcrumb } from './ProductBreadcrumb'
import { SortbySelect } from './SortBySelect'
import {
  CheckboxFilterPopover,
  ColorFilterPopover,
  PriceFilterPopover,
  SizeFilterPopover,
  TraitFilterPopover
} from './Filter'
import { MobileFilter } from './MobileFilter'
import { breadcrumbData } from './_data'
import { ProductCard } from './ProductCard'
import { products } from './_data2'
import { SearchInput } from './SearchInput'
import {sizeFilter, traitData} from './_data'
import { FaTwitter } from 'react-icons/fa'
import { Select, AsyncSelect } from "chakra-react-select";
import { groupedOptions, colorOptions, groupedCountries } from "./docs/data";
import LogRocket from 'logrocket';
import {CENTER_KEY} from './constant';
LogRocket.init('y3fljr/wishlyst');



export const theme = extendTheme(
  {initialColorMode: 'system',
    colors: { ...proTheme.colors, brand: proTheme.colors.purple },
    components: {
      Popover: {
        variants: {
          responsive: {
            popper: {
              maxWidth: 'unset',
              width: 'unset'
            }
          }
        }
      }
    }
  },
  proTheme,
)

const traitData1 = traitData.items;


const mappedColorOptions = colorOptions.map((option) => ({
  ...option,
  colorScheme: option.value
}));

// Input for API CALL FOR KATE
const selectedSubTraits = new Map();
const test1 = [{
  "trait": "Archetype of Power",
  "values": [
      {
          "value": "Enchantress",
          "count": 613
      },
      {
          "value": "Hag",
          "count": 804
      },
      {
          "value": "Mage",
          "count": 747
      },
      {
          "value": "Necromancer",
          "count": 748
      },
      {
          "value": "Occultist",
          "count": 786
      },
      {
          "value": "Seer",
          "count": 606
      },
      {
          "value": "Witch of Will",
          "count": 1
      },
      {
          "value": "Witch of Wisdom",
          "count": 1
      },
      {
          "value": "Witch of Woe",
          "count": 1
      },
      {
          "value": "Witch of Wonder",
          "count": 1
      }
  ]}]



function App() {
  // Instantiate App State Variables
  const [appState, setAppState] = useState("loading");
  const [pageNumber, setPageNumber] = useState(0); //offset for pagination
  const [lastPage, setLastPage] = useState(true);

  // Search Values
  const [selectedCollection, setSelectedCollection] = useState({label: 'Crypto Coven', value: 'ethereum-mainnet/0x5180db8F5c931aaE63c74266b211F580155ecac8'}); // Crypto Coven
  const [collectionTitle, setCollectionTitle] = useState('Crypto Coven');
  
  // Trait Values & API Call & Function w UseEffect Trigger
  const collectionDefaults = [
    { value: "ethereum-mainnet/0x5180db8F5c931aaE63c74266b211F580155ecac8", label: "Crypto Coven" },
    { value: 'ethereum-mainnet/0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D', label: "BoredApeYachtClub"},
    { value: 'ethereum-mainnet/0x8a90CAb2b38dba80c64b7734e58Ee1dB38B8992e', label: "Doodles"},
    { value: 'ethereum-mainnet/0x49cF6f5d44E70224e2E23fDcdd2C053F30aDA28B', label: "Clone X"},
    { value: 'ethereum-mainnet/0xED5AF388653567Af2F388E6224dC7C4b3241C544', label: "Azuki"},
    { value: 'ethereum-mainnet/0x1A92f7381B9F03921564a437210bB9396471050C', label: "Cool Cats"},
  ];
  const [collectionTraits, setCollectionTraits] = useState([]); // Trait Values for entire collection (clean)
  const [traitOptions, setTraitOptions] = useState([]); // Trait Options for Select Component (mapped from collectionTraits to label/value)
  const [optionDefaults, setOptionDefaults] = useState([
    { value: "Archetype of Power", label: "Archetype of Power", color: "#FF5630" },
    { value: "Back Item", label: "Back Item", color: "#36B37E" },
    { value: "Background", label: "Background", color: "#36B37E" },
    { value: "Body Shape", label: "Body Shape", color: "#FF5630" }]); // Trait Defaults for Select Component (mapped from collectionTraits to label/value)


  // API Call to get Traits
  var traitCall = async (selectedCollection) => {
    const options = {
      method: 'GET',
      headers: {accept: 'application/json', 'X-API-Key': 'key630297b53068000b813e764f'}
    };

    let apiCall = 'https://api.center.dev/v1/'+selectedCollection+'/traits?limit=100';
    
    // make API call with parameters and use promises to get response
    let result = await fetch(apiCall, options)
      .then((response) => { //THIS IS HOW YOU ACCESS THE RESPONSE DONT DELETE
        return response.json()})

        // console.log("NFT DATA: TRAIT CALL", result.items);
    let traitData = result.items; 
    setCollectionTraits(traitData);
    if (traitData.length > 8) {
      setTraitFilters([traitData[0],traitData[1],traitData[2],traitData[3]])
    } else {
      let traitDefaultsTemp = [];
      for (let i = 0; i < traitData.length; i++) {
        traitDefaultsTemp.push(traitData[i]);
      }
    setTraitFilters(traitDefaultsTemp);
    }

    // Map Trait Data to Options for Select Component
    let traitOptionsTemp = traitData.map((trait) => ({label: trait.trait, value: trait.trait, color: "#FF5630"}))
    setTraitOptions(traitOptionsTemp);
    if (traitOptionsTemp.length > 8) {
      setOptionDefaults([traitOptionsTemp[0],traitOptionsTemp[1],traitOptionsTemp[2],traitOptionsTemp[3]])
    } else {
      let optionDefaultsTemp = [];
      for (let i = 0; i < traitOptionsTemp.length; i++) {
        optionDefaultsTemp.push(traitOptionsTemp[i]);
      }
    setOptionDefaults(optionDefaultsTemp);
    }
    // setAppState("traitsLoaded");
    return result.Items;  
  }

  useEffect(() => {
    if (selectedCollection) {
      traitCall(selectedCollection.value);
      selectedSubTraits.clear();}
  }, [selectedCollection]);
 

  // Check for Button to Return to Top
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 300) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // Instantiate Traits Array
  const [traitFilters, setTraitFilters] = useState([]);

  // Instantiate NFTs to Render Array
  const [nftsToRender, setNftsToRender] = useState([]);

  // Update Trait Selection Array on Select
  function selectTraits(input) {
    let newTraits = [];
    
    // Create new trait array
    for (let i = 0; i < input.length; i++) {
      let obj = collectionTraits.find(o => o.trait === input[i].value);
      newTraits.push(obj);
    }

    // Calculate removed traits
    let difference = traitFilters.filter(x => !newTraits.includes(x));    
    
    // Remove Traits/Subtraits from Selected Subtraits Mapping
    if (difference.length > 0 && selectedSubTraits.has(difference[0].trait)) {
      selectedSubTraits.delete(difference[0].trait);
      setAppState("filtering");
      setPageNumber(0);
    }
    setTraitFilters(newTraits);
    
  }  
 
  // Delete Subtrait from Selected Subtraits Mapping
  const deleteSubTrait = (trait) => {
    selectedSubTraits.delete(trait);
      setAppState("filtering");
      setPageNumber(0);
  }

  // Initial Get Traits API Call
  

    // API Call to get NFTs w filter
    var APIGetCallFilter = async (searchParam, pageNumber, _selectedCollection) => {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'X-API-Key': 'key630297b53068000b813e764f',
          'content-type': 'application/json'
        },
        body: JSON.stringify(searchParam)
      };
  
      let apiCall = 'https://api.center.dev/v1/'+ _selectedCollection +'/assets/searchByTraits?limit=100&offset='+pageNumber;
      // make API call with parameters and use promises to get response
      let result = await fetch(apiCall, options)
        .then((response) => { //THIS IS HOW YOU ACCESS THE RESPONSE DONT DELETE
          return response.json()})
  
      if (result.onLastPage===false) {
        setLastPage(false);
      } else if (result.onLastPage===true && lastPage===false) {
        setLastPage(true);
      }

      let nftData = result.items;
      //  console.log("NFT DATA: FILTERED", nftData);
      setNftsToRender(nftData);
      setAppState("loaded");
      return result.Items;  
    }

  // API Call to get NFTs w/o filter
  var APIGetCall = async (_selectedCollection) => {
    
    
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'X-API-Key': 'key630297b53068000b813e764f',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        assets: [{TokenID: '1', Address: _selectedCollection.slice(-42)}, {TokenID: '2', Address: _selectedCollection.slice(-42)},
        {TokenID: '3', Address: _selectedCollection.slice(-42)}, {TokenID: '4', Address: _selectedCollection.slice(-42)},
        {TokenID: '5', Address: _selectedCollection.slice(-42)}, {TokenID: '6', Address: _selectedCollection.slice(-42)},
        {TokenID: '7', Address: _selectedCollection.slice(-42)}, {TokenID: '8', Address: _selectedCollection.slice(-42)},
        {TokenID: '9', Address: _selectedCollection.slice(-42)}, {TokenID: '10', Address: _selectedCollection.slice(-42)},
        {TokenID: '11', Address: _selectedCollection.slice(-42)}, {TokenID: '12', Address: _selectedCollection.slice(-42)},
        {TokenID: '13', Address: _selectedCollection.slice(-42)}, {TokenID: '14', Address: _selectedCollection.slice(-42)},
        {TokenID: '15', Address: _selectedCollection.slice(-42)}, {TokenID: '16', Address: _selectedCollection.slice(-42)},
        {TokenID: '17', Address: _selectedCollection.slice(-42)}, {TokenID: '18', Address: _selectedCollection.slice(-42)},
        {TokenID: '19', Address: _selectedCollection.slice(-42)}, {TokenID: '20', Address: _selectedCollection.slice(-42)},
        {TokenID: '21', Address: _selectedCollection.slice(-42)}, {TokenID: '22', Address: _selectedCollection.slice(-42)},
        {TokenID: '23', Address: _selectedCollection.slice(-42)}, {TokenID: '24', Address: _selectedCollection.slice(-42)},
        {TokenID: '25', Address: _selectedCollection.slice(-42)}, {TokenID: '26', Address: _selectedCollection.slice(-42)},
        {TokenID: '27', Address: _selectedCollection.slice(-42)}, {TokenID: '28', Address: _selectedCollection.slice(-42)},
        {TokenID: '29', Address: _selectedCollection.slice(-42)}, {TokenID: '30', Address: _selectedCollection.slice(-42)},
        {TokenID: '31', Address: _selectedCollection.slice(-42)}, {TokenID: '32', Address: _selectedCollection.slice(-42)},
        {TokenID: '33', Address: _selectedCollection.slice(-42)}, {TokenID: '34', Address: _selectedCollection.slice(-42)},
        {TokenID: '35', Address: _selectedCollection.slice(-42)}, {TokenID: '36', Address: _selectedCollection.slice(-42)},
        {TokenID: '37', Address: _selectedCollection.slice(-42)}, {TokenID: '38', Address: _selectedCollection.slice(-42)},
        {TokenID: '39', Address: _selectedCollection.slice(-42)}, {TokenID: '40', Address: _selectedCollection.slice(-42)},
        {TokenID: '41', Address: _selectedCollection.slice(-42)}, {TokenID: '42', Address: _selectedCollection.slice(-42)},]
      })
    };

    let apiCall = 'https://api.center.dev/v1/ethereum-mainnet/assets'
    // make API call with parameters and use promises to get response


    let result = await fetch(apiCall, options)
      .then((response) => { //THIS IS HOW YOU ACCESS THE RESPONSE DONT DELETE
        return response.json()}).catch( error => {
          console.log('error', error)
          })
    
    let nftData = []; 
    // console.log("NFT DATA: ASSETS", result);
  
    for (let i = 0; i < result.length; i++) {
      // console.log("results", result[i])
      if (result[i]!==null) {
        nftData.push(result[i]);
      }
    }
    // console.log("NFT DATA: ASSETSLOOP", nftData);
    setNftsToRender(nftData);
    setAppState("loaded");
    return result.Items;  
  }


  // Filter function that creates the API call then, calls the API
  function filterOnTraits(selectedSubTraits, pageNumber, collectionAddress){
    if (selectedSubTraits.size > 0) {
    let searchParam='{"query": {'
    for (const item of selectedSubTraits.entries()) {
      searchParam += '"' + item[0] + '"' + ': ["'+ item[1] + '"],';
    }
    searchParam = searchParam.slice(0, -1) + '}}';
    searchParam = JSON.parse(searchParam);
    



  APIGetCallFilter(searchParam, pageNumber, collectionAddress);

  } else {
    APIGetCall(collectionAddress);
  }
    
}



  //Run to Filter data due to adition or subtraction of trait
  useEffect(() => {
    if (selectedCollection) {
      setAppState("filtering");
      window.localStorage.setItem('collectionAPIString', selectedCollection.value);
      filterOnTraits(selectedSubTraits, pageNumber, selectedCollection.value);
      setCollectionTitle(selectedCollection.label);
      // console.log("inside useEffect1", selectedCollection);
    }
    else {
      filterOnTraits(selectedSubTraits, pageNumber, window.localStorage.getItem('collectionAPIString'));
      // console.log("inside useEffect2", selectedCollection, window.localStorage.getItem('collectionAPIString'));
    }
    // filterOnTraits(selectedSubTraits, pageNumber, "0x5180db8F5c931aaE63c74266b211F580155ecac8");
    // console.log("inside useEffect Basic", selectedCollection, window.localStorage.getItem('collectionAPIString'));
  },[JSON.stringify(Array.from(selectedSubTraits.entries())),pageNumber,selectedCollection]); // Updates every time selectedSubTraits changes , [selectedSubTraits]


  // Instantiate Email Capture Variable
  const [emailCapture, setEmailCapture] = useState("");
  
  // Return to top of page
  const returnTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  // Next Page Function
  const nextPage = () => {
    setPageNumber(pageNumber+100);
    returnTop();
  }
// App State Management
  if (appState === "loading") {

  } else if (appState === "loaded") {
    // This is the "resting" state of the app
    // Setting a subTrait choice will trigger a state change to "filtering"
    // Then, the app will re-render with the new filter applied because of useEffect
  }  else if (appState === "filtering") {

  }
  return (
<ChakraProvider theme={theme}>

    {/* App Start */}
  <Box as="section" height="100%" overflowY="auto">
    
    {/* Navbar is by itself */}
    <Navbar />

    {/* Master Component List */}
    <Container
      pt={{
        base: '0',
        lg: '0',
      }}
      pb={{
        base: '0',
        lg: '0',
      }}
      >
      <Box
        maxW="7xl"
        mx="auto"
        px={{
          base: '4',
          md: '8',
          lg: '12',
        }}
        // py={{
        //   base: '4',
        //   md: '6',
        //   lg: '8',
        // }}
        >

        {/* Hidden for Demo Purposes, but will be used to navigate to other collections   */}
        {/* <SearchInput/> */}
        <Flex
          justify="space-between"
          align="center"
          display={{
            base: 'flex',
            md: 'flex',
          }}
          >
        {/* Trait Filter Selector */}
        <Box alignItems='baseline' w='100%' mt="6" mb="4">
        <VStack align="left">
        <Text fontSize='lg' mb='2'>Type to Search for an NFT Collection:</Text>
        <CollectionSearch setSelectedCollection={setSelectedCollection} collectionDefaults={collectionDefaults}/>
        </VStack>
        </Box>  
        </Flex>

        {/* Collection Title */}
        <Heading
          size={{
            sm:"sm",
            base:"md", 
              md:"lg"
            }}
          mt={{
            base: '1',
            sm: '2',
            md: '4',
          }}
          mb="8"
          >
          {collectionTitle}
        </Heading>
      
       
        <Flex
          justify="space-between"
          align="center"
          display={{
            base: 'none',
            md: 'flex',
          }}
          >
        {/* Trait Filter Selector */}
        <Box alignItems='baseline' w='100%' mt="2" mb="4">
          <VStack align="left">
          <Text fontSize='lg'>Choose Traits:</Text>
            <Select
              isMulti
              key={JSON.stringify(optionDefaults)}
              defaultValue={optionDefaults}
              name="Traits"
              colorScheme="purple"
              options={traitOptions}
              placeholder="Explore Traits..."
              closeMenuOnSelect={false} 
              onChange={selectTraits}
            />
          </VStack>
        </Box>
        </Flex>

           {/* Live Trait Filters */}
           <Flex
          justify="space-between"
          align="center"
          display={{
            base: 'none',
            md: 'flex',
          }}
          >
        <Box alignItems='baseline' w='100%' mt="6" mb="4">
          <VStack align="left">
        <Text fontSize='lg' mb='2'>Filter Options:</Text>
        
        
        
          <HStack spacing="6">
          
              {/* <Text color={mode('gray.600', 'gray.400')} fontWeight="medium" fontSize="sm">
                Selected Traits:
              </Text> */}
              <SimpleGrid display="inline-grid" spacing="4" columns={6}>
                {traitFilters.map((traitFilters) => (
                <SizeFilterPopover 
                traitData={traitFilters} 
                selectedSubTraits={selectedSubTraits}
                setAppState={setAppState} 
                setPageNumber={setPageNumber}
                /> //key={traitData.id} <-- Sort Traits somehow
              ))}

              </SimpleGrid>
          </HStack>

       
        </VStack>
        </Box>
        </Flex>
        <Flex
          justify="space-between"
          align="center"
          display={{
            base: 'none',
            md: 'flex',
          }}
          >
        <Box alignItems='baseline' w='100%' mt="6" mb="4">
        <VStack align="left">
          <Text fontSize='lg'>Applied Filters:</Text> 
        
        <HStack>
        {(selectedSubTraits.size>0) ? 
        
        ((Array.from(selectedSubTraits.entries())).map((trait) => (<Tag><Center>{trait[0]} : {trait[1]}</Center><CloseButton mr='-2' size='sm' onClick={() => {deleteSubTrait(trait[0])}}/></Tag>) ) ) : <Tag>No Filters Applied</Tag>}
        
        </HStack>
        
        </VStack>
        </Box>
        </Flex>

        <MobileFilter 
        traitFilters={traitFilters} 
        selectedSubTraits={selectedSubTraits}
        setAppState={setAppState} 
        setPageNumber={setPageNumber}
        defaultSelect={optionDefaults}
        defaultSet={setOptionDefaults}
        groupedOptions={traitOptions}
        selectTraits={selectTraits}
        />

      
        {/* Container for rendering NFTs */}
        <Box
          mt={{
            base: '6',
            md: '10',
          }}
          minH="50vh"
          width="full"
          borderWidth="3px"
          rounded="lg"
          borderStyle="none"
          >
          {(nftsToRender !== null) ? (  
          <Box>
                        {(appState === "loading" || appState === "filtering") ? <Center mb='10'><Spinner/></Center>: null}
          <SimpleGrid
            columns={{
              base: 1,
              sm: 2,
              lg: 3,
            }}
            gap={{
              base: '8',
              lg: '12',
            }}
            >

            {/* Render NFTs */} 

            {nftsToRender.map((product) => (
              <ProductCard 
              key={product.token_id} 
              product={product} 
              emailCapture={emailCapture} 
              setEmailCapture={setEmailCapture}/>
            ))}
          
          </SimpleGrid>
          
          {/* Pagination */}
          {lastPage ? (<Center mt='10'><Button  colorScheme="purple" variant="outline" 
          display={{
            base: 'none',
            md: 'flex',
          }}
          style={{
            position: 'fixed',
            bottom: '5%',
            right: '5%',
            transform: `translateY(${show ? '0' : '100px'})`,
            transition: 'transform 0.3s ease-in-out',
          }}
          onClick={returnTop}>Return to Top</Button>
          <Button  colorScheme="purple" variant="outline" 
          display={{
            base: 'flex',
            md: 'none',
          }}
          onClick={returnTop}>Return to Top</Button>
          </Center>)
          : (<Center mt='10'><VStack spacing='5'><Button colorScheme="purple" variant="outline" onClick={nextPage}>Load More</Button>
            <Button  colorScheme="purple" variant="outline" 
          display={{
            base: 'none',
            md: 'flex',
          }}
          style={{
            position: 'fixed',
            bottom: '5%',
            right: '5%',
            transform: `translateY(${show ? '0' : '100px'})`,
            transition: 'transform 0.3s ease-in-out',
          }}
          onClick={returnTop}>Return to Top</Button>
          <Button  colorScheme="purple" variant="outline" 
          display={{
            base: 'flex',
            md: 'none',
          }}
          onClick={returnTop}>Return to Top</Button>
          </VStack></Center>)}

          </Box>) : <Center><VStack><Center><Text fontSize="5xl" align='center' >No NFTs Found</Text></Center>
          <Center><Text fontSize="5xl">😅</Text></Center>
          <Center><Text align='center' fontSize="4xl">Try deleting one of the filters!</Text></Center></VStack></Center>}

        </Box>
      
    
  
        
          {/* Developer Tag at Bottom */}
          <Center mb='12' mt='12'>
            <VStack spacing={4}>
              <MasterComponent/>
              <Link href="https://twitter.com/vfWishLyst" target="_blank"><Icon as={FaTwitter} boxSize="5" /></Link>
            </VStack>
          </Center>


      </Box>
    </Container>
  </Box>
</ChakraProvider>
  );
}

export default App;
