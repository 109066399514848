import { Box,FormControl, FormLabel, HStack, useRadioGroup, SimpleGrid, Wrap, usePopoverContext, } from '@chakra-ui/react'
import * as React from 'react'
import { SizePickerButton } from './SizePickerButton'

export const SizePicker = (props) => {
  const { onClickApply, options, rootProps, hideLabel, label, ...rest } = props
  const { onClose } = usePopoverContext()
  const traitData = props.traitData;
  const selectedSubTraits = props.selectedSubTraits;
  const { getRadioProps, getRootProps, value } = useRadioGroup(rest)
  const selectedOption = options.find((option) => option.value == value)
  return (
    <FormControl {...rootProps}>
      {!hideLabel && (
        <FormLabel fontSize="sm" fontWeight="medium">
          {label ?? `Count: ${selectedOption?.label}`}
        </FormLabel>
      )}
      
      <Box className="wrapClass">
      <Wrap className="wrapClass">  
      {/* <SimpleGrid wrap="wrap"> */}
      {/* <Stack {...getRootProps()}> */}
      
        {options.map((option) => (
     
          <SizePickerButton
            key={option.value}
            label={option.label}
            {...getRadioProps({
              value: option.value,
            })}
            onClickApply={onClickApply}
            traitData = {traitData}
            selectedSubTraits = {selectedSubTraits}
          />
      
        ))}
    
    </Wrap>
      </Box>
    </FormControl>
  )
}
