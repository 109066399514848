import {
  AspectRatio,
  Box,
  HStack,
  Icon,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useBreakpointValue,

} from '@chakra-ui/react'
import * as React from 'react'
import { useState } from "react";
import { FiEye, FiStar } from 'react-icons/fi'
import { FaStar } from 'react-icons/fa'
import { Rating } from './Rating'
import { PriceTag } from './PriceTag'
import { ProductCardButton } from './ProductCardButton'
import emailjs from '@emailjs/browser';



export const ProductCard = (props) => {
  const { product } = props;
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })

  const emailCapture=props.emailCapture;
  const setEmailCapture=props.setEmailCapture;
  
  var emailParams = {
    reply_to: emailCapture,
    collectionTitle: product.collection_name,
    nftName: product.name,
    tokenID: product.token_id,
    nftLink: product.url,
    nftImage: product.mediumPreviewImageUrl,
  }

  function sendEmail() {
    //Send email to Client about Saved NFT
        emailjs.send("service_tprcqsf", "wishlyst_id", emailParams, "01KJO-QDJo4-Wm8z8")
          .then((result) => {
              console.log(result.text);
              toast({
                title: `Your Wish has been Saved!`,
                status: 'success',
                isClosable: true,
              })
          }, (error) => {
              console.log(error.text);
          });
      };

  // Flag for "wished for" state
  const [starIcon, setStarIcon] = useState(false);

// Email Capture Modal functionality  

    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    
//success toast
const toast = useToast()    




// Set Star Values
  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }
  const randomStar = getRandomArbitrary(-10, 20).toFixed(0)
  var starValueDefault = (randomStar>0 ? randomStar : 0);
  const [starValue, setStarValue] = useState(starValueDefault);


// Increment Star and capture email for NFT saving
  function plusOne() {
    if (emailCapture==="" || !emailCapture.includes("@")) {
      onOpen();
      return
    }
    if (!starIcon) {
      setStarValue(parseInt(starValue)+1);
      setStarIcon(true);
      sendEmail();
    }
    // console.log("emailCapture", emailCapture);
  }

  function saveEmail(e) {
    setEmailCapture(e.target.value);
  }

  return (
    <Stack spacing="4">
      <Box position="relative" className="group">
      {isDesktop ? (
      <Link 
          href={product.url} //Insert Center Link here 
          target="_blank"> 
        <AspectRatio ratio={3 / 4}>
          <Image
            src={product.mediumPreviewImageUrl} 
            // metadata.image <- high res but requires image processing (IPFS/SVG/ETC) mediumPreviewImageUrl
            alt={product.name}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius="md"
          />

        </AspectRatio>
        </Link>) :
        <AspectRatio ratio={3 / 4}>
        <Image
          src={product.mediumPreviewImageUrl}
          alt={product.name}
          draggable="false"
          fallback={<Skeleton />}
          borderRadius="md"
        />

      </AspectRatio>}
        {/* Make star visible on mobile */}
          <Box
          position="absolute"
          className="container"
          bottom="1"
          right="1">
          <HStack spacing="3">
            {/* <ProductCardButton aria-label="View details" icon={<Icon as={FiEye} boxSize="5" />} /> */}
            <ProductCardButton
              aria-label="Add to favourite"
              icon={<Icon as={starIcon ? FaStar : FiStar} boxSize="7" />}
              onClick={plusOne}
              _focus={{
                borderColor: "rgba(236,97,14, 1)",
                color: "rgba(236,97,14, 1)"
              }}
            />
          </HStack>

          <>
        <Modal
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Share an Email to Save Your Wishes</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <Input ref={initialRef} placeholder='YourEmail@gmail.com' onChange={saveEmail}/>
              </FormControl>
  

            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>

        </Box>


      </Box>
      <Stack spacing="1">
        <HStack justifyContent="space-between">
          <Text
            color={useColorModeValue('black', 'white')}
            fontSize="sm"
            fontWeight="semibold"
            letterSpacing="wider"
            textTransform="uppercase"
          >
            {product.name}
          </Text>
          <Text>#{product.token_id}</Text>
          {/* Insert Token ID */}
          {/* <PriceTag
            currency={product.currency}
            price={product.price}
            priceProps={{
              fontSize: 'sm',
              fontWeight: 'semibold',
              color: useColorModeValue('black', 'white'),
            }}
          /> */}
        </HStack>
        <HStack>
          <Rating defaultValue={product.rating} size="sm" />
          <Text fontWeight="medium" fontSize="sm" color={useColorModeValue('gray.500', 'gray.200')}>
            {starValue} Wishes
          </Text>
        </HStack>
      </Stack>
    </Stack>
  )
}
