import React, {useState} from "react";
import {
  Box,
  Button,
  Center,
  Image,
  Heading,
  Flex,
  Badge,
  Link,
  Text,
  VStack,
  useClipboard
} from '@chakra-ui/react';
import {  CENTER_KEY } from "../constant";



export default function MasterComponent(props) {

// Tasks:
// 0. Create Header, Menu, & Core Page UI
// 00. Create Filter Lists UI
// 000. Create Product Card Grid UI w Pagination
// 1. Retrieve the NFT List (first 48) + set up offset & Pagination (1...99+) & Render NFTs https://docs.center.dev/reference/getassets
// 2. X Retrieve Trait list & Save It - WILL NEED TO ADD A CHECK FOR MULTIPLE LISTS OF TRAITS BUT FINE FOR NOW
// 3. Use Trait list to generate Trait Filter UX (store the values to use for API Call count checks >100 then two+ calls will need to be made w offset)
// 4. Use Trait Filter to craft API call to retrieve filtered NFT List https://docs.center.dev/reference/searchassetswithincollectionbytraits
// 5. Each selection resets call and Rerenders NFT list (pagination will need to be reset)
// 6. Add Heap/GA/LogRocket to track usage

// Data Management
const [traits, setTraits] = useState([]);

// // State Management
const [loadState, updateLoadState] = useState("loading"); // Set initial API state & use for managing main app state




// Retrieve Traits from Center API (Just Crypto Coven for Now) THIS IS WORKING
// var getTraits = async () => {
//     var myHeaders = new Headers();
//     myHeaders.append("X-API-Key", CENTER_KEY);

//   var requestOptions = {
//     method: 'GET',
//     headers: myHeaders,
//     redirect: 'follow'
//   };

//   let resp = await fetch("https://api.center.dev/v1/ethereum-mainnet/0x5180db8F5c931aaE63c74266b211F580155ecac8/traits?limit=100", requestOptions)
//     .then(response => response.text())
//     .then(result => {return result})
//     .catch(error => console.log('error', error));

//   let respJSON = JSON.parse(resp);
//   setTraits(respJSON);
//   updateLoadState("traitsLoaded");
//   console.log(respJSON);
// }







// // // App State Flow
// if (loadState === "loading") {
//   getTraits();
// } else if (loadState === "traitsLoaded") {
//   console.log("Traits Loaded", traits);
// } 
// else if (loadState === "ProfileDataRetrieved") {
//   // getCastData();
// } else if (loadState === "CastDataRetrieved") {
//   // shapeCastData();
// }



return (
    <Box>
      <Badge colorScheme="purple"><Link href="https://linktr.ee/camarmstrong" target="_blank">Made with ✨ by @Cameron</Link></Badge>
    </Box>


  )}




