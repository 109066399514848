import {
  Button,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  HStack,
  Link,
  Text,
} from '@chakra-ui/react'
import React from 'react'

export const FilterDrawer = (props) => {
  const { onClose, isOpen, children, onClickApply } = props
  let selectedSubTraits = props.selectedSubTraits;
  let setAppState = props.setAppState;
  let setPageNumber = props.setPageNumber;

  function onClearAll () {
    setAppState("filtering");
    selectedSubTraits.clear();
    setPageNumber(0);
  }

  const returnTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  return (
    <Drawer
      placement="bottom"
      isFullHeight
      isOpen={isOpen}
      onClose={onClose}
      // /*`trapFocus` and `blockScrollOnMount` are only switched off so that the preview works properly. */ blockScrollOnMount={
      //   false
      // }
      // trapFocus={false}
    >
      <DrawerContent>
        <DrawerHeader px="4" borderBottomWidth="1px">
          <Flex justify="space-between" align="center">
            <CloseButton onClick={onClose} />
            <Text fontWeight="semibold" fontSize="md">
              Filter NFTs by Traits
            </Text>
            <HStack spacing="4">
              <Link
                textDecor="underline"
                fontSize="sm"
                onClick={() => {
                  onClearAll?.()
                }}
              >
                Clear
              </Link>
            </HStack>
          </Flex>
        </DrawerHeader>
        <DrawerBody pl="6" pr="6">{children}</DrawerBody>
        <DrawerFooter px="4" borderTopWidth="1px">
          <Button
            width="full"
            size="lg"
            fontSize="md"
            colorScheme="blue"
            onClick={() => {
              onClickApply?.()
              onClose()
              returnTop()
            }}
          >
            Show results
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
