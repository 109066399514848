import {
  HStack,
  VStack,
  Icon,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  SimpleGrid,
  useColorModeValue as mode,
  usePopoverContext,
} from '@chakra-ui/react'
import React from 'react'
import { HiChevronDown } from 'react-icons/hi'
import { FilterActionButtons } from './FilterActionButtons'
import './App.css'

export const FilterPopoverButton = (props) => {
  const { label, icon, selected, selectedSubTraits } = props
  const selectCheck = selectedSubTraits.has(label);
  return (
    <PopoverTrigger>
       {selectCheck ? (
       <HStack
       justify="space-between"
       position="relative"
       as="button"
       fontSize="sm"
       borderWidth="1px"
       zIndex="0"
       rounded="lg"
       paddingStart="3"
       paddingEnd="2"
       paddingY="1.5"
       spacing="1"
       borderColor= 'purple.500'
       
     >
       {icon && <Icon as={icon} boxSize="2" />}
       <Text fontWeight="medium" 
       sx={{
             wordWrap: "normal"
           }}>{label}</Text>
       <Icon as={HiChevronDown} fontSize="xl" color="gray.400" />
     </HStack>
       ) : ( 
      <HStack
        justify="space-between"
        position="relative"
        as="button"
        fontSize="sm"
        borderWidth="1px"
        zIndex="0"
        rounded="lg"
        paddingStart="3"
        paddingEnd="2"
        paddingY="1.5"
        spacing="1"
        // wrap="wrap"
        data-selected={selected || undefined}
        _expanded={{
          bg: mode('gray.100', 'gray.700'),
        }}
        _selected={{
          bg: 'blue.50',
          borderColor: 'blue.500',
        }}
      >
        {icon && <Icon as={icon} boxSize="2" />}
        <Text fontWeight="medium" 
        sx={{
              wordWrap: "normal"
            }}>{label}</Text>
        <Icon as={HiChevronDown} fontSize="xl" color="gray.400" />
      </HStack>
        )
      }
    </PopoverTrigger>

  )

}
export const FilterPopoverContent = (props) => {
  const { header, children, onClickCancel, onClickApply, isCancelDisabled } = props
  const { onClose } = usePopoverContext()
  // console.log("children", children)
  return (
    <PopoverContent
      _focus={{
        shadow: 'base',
        outline: 0,
        
      }}
      _focusVisible={{
        shadow: 'base',
      }}
      variant="responsive"
  
    >
      {header && <PopoverHeader srOnly >{header}</PopoverHeader>}

      <PopoverBody minW={{ base: "100%", lg: "100%" }} maxH="300" overflowY="auto" padding="6" variant="responsive" className="testClass" >{children}</PopoverBody>
  
      {/* <PopoverFooter className="testClass" >
        <FilterActionButtons
          onClickCancel={() => {
            onClickCancel?.()
            onClose()
          }}
          isCancelDisabled={isCancelDisabled}
          onClickApply={(e) => {
            onClickApply?.(e)
            onClose()
          }}
        />
      </PopoverFooter> */}
    </PopoverContent>
  )
}
