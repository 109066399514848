import {
  Box,
  Center,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  SimpleGrid,
  propNames,
  VStack,
  Tag,
  CloseButton,
  Wrap
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { MdFilterList } from 'react-icons/md'
import { CheckboxFilter } from './CheckboxFilter'
import { ColorPicker } from './ColorPicker'
import { FilterDrawer } from './FilterDrawer'
import { PriceRangePicker } from './PriceRangePicker'
import { SizePicker } from './SizePicker'
import { SortbySelect } from './SortBySelect'
import { sizeFilter } from './_data'
import {  SizeFilterPopover} from './Filter'

import { Select } from "chakra-react-select";


export const MobileFilter = (props) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  let traitFilters = props.traitFilters;
  let selectedSubTraits = props.selectedSubTraits;
  let setAppState = props.setAppState;
  let setPageNumber = props.setPageNumber;
  let defaultSelect = props.defaultSelect;
  let groupedOptions = props.groupedOptions;
  let selectTraits = props.selectTraits;

  const [showFilter, setShowFilter] = useState(false)
  const [filterZ, setFilterZ] = useState(9999)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 300) {
        setShowFilter(true)
      } else {
        setShowFilter(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])


  return (
    <>
      <Flex
        width="full"
        justify="space-between"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
        <HStack
          as="button"
          fontSize="sm"
          type="button"
          px="3"
          py="1"
          onClick={onOpen}
          borderWidth="1px"
          rounded="md"
        >
          <Icon as={MdFilterList} />
          <Text fontSize='xl'>Filter NFTs by Traits</Text>
        </HStack>
       
      </Flex>
      <Flex
        width="full"
        display={{
          base: 'flex',
          md: 'none',
        }}
      >
         <Button
          onClick={onOpen}
          colorScheme="purple" variant="outline"
          style={{
            position: 'fixed',
            bottom: '5%',
            left: "35%",
            width: '30%',
            transform: `translateY(${showFilter ? '0' : '100px'})`,
            transition: 'transform 0.3s ease-in-out',
            zIndex: isOpen ? '0' : '9999'
          }}
        >
        Filter Traits
        </Button>
      </Flex>
      <FilterDrawer isOpen={isOpen} onClose={onClose} selectedSubTraits={selectedSubTraits}
      setAppState={setAppState} 
      setPageNumber={setPageNumber}
      >
        <Stack spacing="6" divider={<StackDivider />} mt='6'>
          <MobileSizeFilter 
          traitFilters={traitFilters} 
          selectedSubTraits={selectedSubTraits}
          setAppState={setAppState} 
          setPageNumber={setPageNumber}
          defaultSelect={defaultSelect}
          groupedOptions={groupedOptions}
          selectTraits={selectTraits}
          />
        </Stack>
      </FilterDrawer>
    </>
  )
}



const MobileSizeFilter = (props) => {
  let traitFilters = props.traitFilters;
  let selectedSubTraits = props.selectedSubTraits;
  let setAppState = props.setAppState;
  let setPageNumber = props.setPageNumber;
  let defaultSelect = props.defaultSelect;
  let groupedOptions = props.groupedOptions;
  let selectTraits = props.selectTraits;

  const deleteSubTrait = (trait) => {
    selectedSubTraits.delete(trait);
      setAppState("filtering");
      setPageNumber(0);
  }

  return (
    <Box w='100%'>
      
      <Box alignItems='baseline' w='100%' mt="4" mb="4">
          <VStack align="left">
          <Text fontSize='lg'>Choose Traits:</Text>
            <Select
              isMulti
              key={JSON.stringify(defaultSelect)}
              isClearable={false}
              isSearchable={ false }
              defaultValue={defaultSelect}
              name="Traits"
              colorScheme="purple"
              options={groupedOptions}
              placeholder="Explore Traits..."
              closeMenuOnSelect={false} 
              onChange={selectTraits}
              blurInputOnSelect={false}
            />
          </VStack>
        </Box>
        <Box fontWeight="semibold" mb="2">
        Filter Options:
      </Box>
      <Center>
              <SimpleGrid  display="inline-grid" spacing="2" columns={3} pr='10' maxHeight='200' overflowY="scroll">
                {traitFilters.map((traitFilters) => (
                <SizeFilterPopover 
                traitData={traitFilters} 
                selectedSubTraits={selectedSubTraits}
                setAppState={setAppState} 
                setPageNumber={setPageNumber}
                /> //key={traitData.id} <-- Sort Traits somehow
              ))}

              </SimpleGrid>
          </Center>
        <Box alignItems='baseline' w='100%' mt="6" mb="4">
        <VStack align="left">
          <Text fontSize='lg'>Applied Filters:</Text> 
          <Wrap className="wrapClass">
          {(selectedSubTraits.size>0) ? 
        
        ((Array.from(selectedSubTraits.entries())).map((trait) => (<Tag><Center>{trait[0]} : {trait[1]}</Center><CloseButton size='sm' onClick={() => {deleteSubTrait(trait[0])}}/></Tag>) ) ) : <Tag>No Filters Applied</Tag>}
        </Wrap>
        </VStack>
        </Box>
    </Box>
  )
}

