import {
  Button,
  chakra,
  useColorModeValue,
  useRadio,
  useTheme,
  VisuallyHidden,
  usePopoverContext,
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import * as React from 'react'
import {useCallback,} from 'react';

export const SizePickerButton = (props) => {
  const { value, label, onClickApply, } = props
  const { getInputProps, htmlProps, getCheckboxProps, getLabelProps } = useRadio(props)
  const { onClose } = usePopoverContext()
  const theme = useTheme()
  const traitData = props.traitData;
  const selectedSubTraits = props.selectedSubTraits;

  //Color Mode
  let colorColor =useColorModeValue('gray.600', 'gray.400');
  let borderColor=useColorModeValue('gray.200', 'gray.600');
  let checkedColor1 = useColorModeValue('blue.500', 'blue.200');
  let checkedColor2 = useColorModeValue('blue.50', transparentize('blue.200', 0.12)(theme));
  let checkedColor3 = useColorModeValue('blue.500', 'blue.200');


  return (
    <chakra.label {...htmlProps}>
      <chakra.input {...getInputProps()} />
      {(selectedSubTraits.get(traitData.trait)!==value) ? (
      <Button
      as="span"
      px="0"
      pl={2}
      pr={2}
      cursor="pointer"
      variant="outline"
      colorScheme="blue"
      color={colorColor}
      borderRadius="base"
      borderColor={borderColor}
      
      _focus={{
        boxShadow: 'none',
      }}
      _focusVisible={{
        boxShadow: 'outline',
      }}
      // {...getCheckboxProps()}
        onClick={
          (e) => {
          onClickApply?.(e);
            selectedSubTraits.set(traitData.trait, value);
            // console.log("selectedSubTraitsinner", selectedSubTraits);
            // setPageNumber(0);
          
          onClose()
        }}
      >
        {value}
      </Button>
      ) : (
        <Button
      as="span"
      px="0"
      pl={2}
      pr={2}
      cursor="pointer"
      variant="outline"
      colorScheme="blue"
      color={colorColor}
      borderRadius="base"
      borderColor={borderColor}
      _checked={{
        color: checkedColor1,
        bg: checkedColor2,
        borderColor: checkedColor3,
        borderWidth: '2px',
      }}
      _focus={{
        boxShadow: 'none',
      }}
      _focusVisible={{
        boxShadow: 'outline',
      }}
      {...getCheckboxProps()}
        onClick={
          (e) => {
          onClickApply?.(e);
            selectedSubTraits.delete(traitData.trait);
            // console.log("selectedSubTraitsinner", selectedSubTraits);
            // setPageNumber(0);
          
          onClose()
        }}
      >
        {value}
      </Button>
        )}
      <VisuallyHidden {...getLabelProps()}>{label} selected</VisuallyHidden>
    </chakra.label>
  )
}
