import { Box, Popover, Select } from '@chakra-ui/react'
import React from 'react'
import { CheckboxFilter } from './CheckboxFilter'
import { ColorPicker } from './ColorPicker'
import { PriceRangePicker } from './PriceRangePicker'
import { formatPrice } from './PriceTag'
import { SizePicker } from './SizePicker'
import { FilterPopoverButton, FilterPopoverContent } from './FilterPopover'
import { useFilterState } from './useFilterState'

export const SizeFilterPopover = (props) => {
  const traitData = props.traitData;
  const selectedSubTraits = props.selectedSubTraits;
  const setAppState = props.setAppState;
  const setPageNumber = props.setPageNumber;
  
  
  const state = useFilterState({
    defaultValue: '',
    onSubmit: editSubTraitMap
  })

  function editSubTraitMap(value) {
    // console.log("state update")
    setAppState("filtering");
    // selectedSubTraits.set(traitData.trait, value);
    // console.log("selectedSubTraits", selectedSubTraits);
    setPageNumber(0);
  }
  
  return (
    
    <Popover 
    
    placement="bottom-start">
      <FilterPopoverButton label={traitData.trait} selectedSubTraits={selectedSubTraits}/>
      <FilterPopoverContent 
        isCancelDisabled={!state.canCancel}
        onClickApply={state.onSubmit}
        onClickCancel={state.onReset}
        
        className="testClass" 
      >
        <SizePicker
          hideLabel
          value={state.value}
          onChange={state.onChange}
          options={traitData.values}
          onClickApply={state.onSubmit}
         traitData = {traitData}
       selectedSubTraits = {selectedSubTraits}
        />
      </FilterPopoverContent>
    </Popover>
  )
}

